<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-datepicker
            :range="true"
            defaultStart="-2d"
            defaultEnd="6d"
            label="정비요청일"
            name="requestDts"
            v-model="searchParam.requestDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" label="정비요청 부서" name="reqDeptCd" v-model="searchParam.reqDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" label="정비오더 작업부서" name="workDeptCd" v-model="searchParam.workDeptCd" :isFirstValue="false" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="WO_WORK_REQ_STEP_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="woWorkReqStepCd"
            label="진행상태"
            v-model="searchParam.woWorkReqStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="정비요청 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'woWorkReqStepName'">
          <q-chip
            outline square 
            class="full-size-chip"
            :color="getColors(props.row.woWorkReqStepCd)"
            text-color="white">
            {{props.row['woWorkReqStepName']}}
          </q-chip>
        </template>
        <template v-if="col.name === 'equipmentTypeName'">
          <q-chip
            outline square 
            color="blue"
            text-color="white"
            class="q-ma-none customchipdept full-size-chip">
            {{props.row['equipmentTypeName']}}
          </q-chip>
        </template>
        <template v-if="col.name === 'emergencyFlagName'">
          <q-chip
            v-if="props.row['emergencyFlagName'] == '긴급'"
            outline square 
            color="red"
            text-color="white"
            class="q-ma-none customchipdept">
            {{props.row['emergencyFlagName']}}
          </q-chip>
          <span v-else>{{props.row['emergencyFlagName']}}</span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-order-request',
  data() {
    return {
      searchParam: {
        plantCd: null,
        requestDts: [],
        workDeptCd: '',
        reqDeptCd: '',
        woWorkReqStepCd: null,
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'woWorkReqStepName',
            field: 'woWorkReqStepName',
            label: '진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:150px',
          },
          {
            fix: true,
            name: 'emergencyFlagName',
            field: 'emergencyFlagName',
            label: '우선순위',
            align: 'center',
            sortable: true,
            type: 'custom',
            style: 'width:90px',
          },
          {
            fix: true,
            name: 'woRequestName',
            field: 'woRequestName',
            label: '작업명',
            align: 'left',
            sortable: true,
            style: 'width:300px',
            type: 'link'
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            type: 'custom',
            style: 'width:230px',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'locationName',
            field: 'locationName',
            label: '장소',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '정비요청 부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'deptNames',
            field: 'deptNames',
            label: '정비오더 작업부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'woWorkTypeName',
            field: 'woWorkTypeName',
            label: '작업분류',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'woRequestDt',
            field: 'woRequestDt',
            label: '요청일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.wod.workreq.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '정비요청 상세';
      this.popupOptions.param = {
        woRequestId: row ? row.woRequestId : '',
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'./workOrderRequestDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWRSC00001': // 작성중
          returnColor = 'orange';
          break;
        case 'WWRSC00002': // 승인요청중
          returnColor = 'purple';
          break;
        case 'WWRSC00003': // 승인완료
          returnColor = 'green';
          break;
        case 'WWRSC00009': // 접수 반려
          returnColor = 'red';
          break;
        case 'WWRSC00010': // 접수 승인
          returnColor = 'blue';
          break;
      }

      return returnColor;
    },
  }
};
</script>
